<template>
  <div class="home">
    <section class="nav container m-auto text-left p-5 mt-12 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 justify-between">
      <div class="mr-10"><router-link to="/">Darrius Taylor</router-link></div>
      <ul class="flex md:place-self-end lg:place-self-end xl:place-self-end">
        <li class="mr-10"><a href="https://medium.com/@double_are" target="_blank" rel="noopener noreferrer">Case Studies</a></li>
        <li class="mr-10"><a href="https://www.linkedin.com/in/darriustaylor/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        <li class="mr-10"><a href="https://mail.google.com/mail/?view=cm&fs=1&to=me@darriustaylor.com&su=Portfolio | Hi" target="_blank" rel="noopener noreferrer">Email</a></li>
      </ul>
    </section>
    <section class="container m-auto text-left p-5 mb-24">
      <h1 class="header-title mb-10 text-gradient">Build Visions, Define Reality</h1>
      <p class="header-blurb mb-4">Darrius Taylor is a <span class="font-semibold">Product Designer</span> and <span class="font-semibold">Software Engineer</span> with 10 years of experience building and designing applications. 
      I use technology as a medium to rapidly develop innovative experiences for Capital One, AARP and Symplicity.</p>
      <p class="header-blurb mb-4">I have extensive experience working with engineers, directors, and designers to ensure user needs are aligned with business goals.</p>
    </section>
    <Project
      v-for="(project, index) in projects" 
      v-bind:key="`project-${index}`"
      :id="project.id"
      :styles="project.styles" 
      :header="project.header"
      :imgs="project.imgs"
      :metadata="project.metadata"
      :blurb="project.blurb" />
    <hr />
    <section class="footer-container text-left px-5 py-24">
      <div class="container m-auto">
        <div class="footer-title font-semibold mb-6">Social Media</div>
        <ul class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 md:place-self-end lg:place-self-end xl:place-self-end">
          <li class="bg-gray-50 border border-gray-700 mr-10 mb-10 rounded-md">
            <a class="p-6 block" href="https://medium.com/@double_are" target="_blank" rel="noopener noreferrer">
              <div class="text-2xl font-bold text-gradient">Case Studies</div>
              <div class="text-lg">Detailed research case studies</div>
            </a>
          </li>
          <li class="bg-gray-50 border border-gray-700 mr-10 mb-10 rounded-md">
            <a class="p-6 block" href="https://www.linkedin.com/in/darriustaylor/" target="_blank" rel="noopener noreferrer">
              <div class="text-2xl font-bold text-gradient">LinkedIn</div>
              <div>Career Experience</div>
            </a>
          </li>
          <li class="bg-gray-50 border border-gray-700 mr-10 mb-10 rounded-md">
            <a class="p-6 block" href="https://mail.google.com/mail/?view=cm&fs=1&to=me@darriustaylor.com&su=Portfolio | Hi" target="_blank" rel="noopener noreferrer">
              <div class="text-2xl font-bold text-gradient">Email</div>
              <div>me@darriustaylor.com</div>
            </a>
          </li>
        </ul>
      </div>
    </section>
    <section class="creator text-left px-5 py-6">
      <div class="container m-auto">I designed and coded this website w/ ❤️ <span class="ml-1 font-semibold"> 2021</span></div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src 
import Project from '@/components/Project.vue'
import KillUX_Home from '@/assets/kill_ux/KillUX_Home.jpg'
import KillUX_TargetUser from '@/assets/kill_ux/KillUX_Target User.jpg'
import KillUX_Illustrations from '@/assets/kill_ux/KillUX_Illustrations.jpg'

import WHERETOSPEND_Home from '@/assets/where_to_spend/WHERETOSPEND_Home.jpg'
import WHERETOSPEND_IncreaseBudget from '@/assets/where_to_spend/WHERETOSPEND_IncreaseBudget.jpg'
import WHERETOSPEND_Compare from '@/assets/where_to_spend/WHERETOSPEND_Compare.jpg'
import WHERETOSPEND_Address from '@/assets/where_to_spend/WHERETOSPEND_Address.jpg'
import WHERETOSPEND_TweetRep from '@/assets/where_to_spend/WHERETOSPEND_TweetRep.jpg'

import CLOUDDETOUR_Home from '@/assets/cloud_detour/CLOUDDETOUR_Home.jpg'
import CLOUDDETOUR_Components from '@/assets/cloud_detour/CLOUDDETOUR_Components.jpg'
import CLOUDDETOUR_Templates from '@/assets/cloud_detour/CLOUDDETOUR_Templates.jpg'
import CLOUDDETOUR_CreateExperiment from '@/assets/cloud_detour/CLOUDDETOUR_CreateExperiment.jpg'
import CLOUDDETOUR_ExperimentResults from '@/assets/cloud_detour/CLOUDDETOUR_ExperimentResults.jpg'

import LINEUP_Home from '@/assets/line_up/LINEUP_Home.jpg'
import LINEUP_Ad from '@/assets/line_up/LINEUP_Ad.jpg'
import LINEUP_Search from '@/assets/line_up/LINEUP_Search.jpg'
import LINEUP_AddToList from '@/assets/line_up/LINEUP_AddToList.jpg'
import LINEUP_Icebreaker from '@/assets/line_up/LINEUP_Icebreaker.jpg'
import LINEUP_Favorites from '@/assets/line_up/LINEUP_Favorites.jpg'
import LINEUP_MentionsAndProfile from '@/assets/line_up/LINEUP_Mentions&Profile.jpg'

export default {
  name: 'Home',
  components: {
    Project
  },
  data() {
    return {
      mediumProjects: [
        {
          id: 'cloud_detour',
          img: { src: '', alt: '' },
          title: '',
          description: '',
        },
        {
          id: 'copy_ai',
          img: { src: '', alt: '' },
          title: 'A StartUp’s MVP — Redesign',
          description: '',
        },
        {
          id: 'detour_design_sprint',
          img: { src: '', alt: '' },
          title: '',
          description: '',
        },
        {
          id: 'line_up',
          img: { src: '', alt: '' },
          title: '',
          description: '',
        }
      ],
      projects: [
        {
          id: 'cloud_detour',
          styles: {
            bg: '#3359A6',
            titleText: '#ffffff',
            descriptionText: '#cccccc',
            otherText: '#eeeeee'
          },
          header: {
            project: '',
            title: 'Cloud Detour',
            description: `Build resilience to failure, maintain customer trust, and improve incident response with Cloud Detour's Chaos Engineering platform.`,
          },
          imgs: [
            { src: CLOUDDETOUR_Home, alt: 'CloudDetour Home Page' },
            { src: CLOUDDETOUR_Components, alt: 'CloudDetour Components' },
            { src: CLOUDDETOUR_Templates, alt: 'CloudDetour Templates' },
            { src: CLOUDDETOUR_CreateExperiment, alt: 'CloudDetour Create Experiment' },
            { src: CLOUDDETOUR_ExperimentResults, alt: 'CloudDetour Experiment Results' },
          ],
          metadata: {
            client: 'Capital One',
            role: 'Design Lead, UX & UI Designer & Developer',
            date: '2020-2021',
            demo: '',
            caseStudy: {
              title: 'Detour: Designing For Adoption & Resiliency',
              link: 'https://medium.com/@double_are/detour-designing-for-adoption-resiliency-72af899231a1'
            },
            tools: 'VUE, Figma, Analytics, Trello, Google Sheets, Google Forms, HTML, SCSS'
          },
          blurb: `Improve adoption & retention of a tech focused application while identifying how the application would align with Capital One's business initiatives.`
        },
        {
          id: 'where_to_spend',
          styles: {
            bg: '',
            titleText: '#12C000',
            descriptionText: '',
          },
          header: {
            project: '',
            title: 'Where To Spend',
            description: `Where do you want congress to spend money?`,
          },
          imgs: [
            { src: WHERETOSPEND_Home, alt: 'WhereToSpend Home Page' },
            { src: WHERETOSPEND_IncreaseBudget, alt: 'WhereToSpend Increase Budget' },
            { src: WHERETOSPEND_Compare, alt: 'WhereToSpend Compare' },
            { src: WHERETOSPEND_Address, alt: 'WhereToSpend Address' },
            { src: WHERETOSPEND_TweetRep, alt: 'WhereToSpend Tweet Representative' },
          ],
          metadata: {
            client: 'Where To Spend',
            role: 'Product Design Lead, UX & UI Designer & Developer',
            date: '2021',
            demo: 'wheretospend.org',
            caseStudy: {},
            tools: 'VUE, Twitter API, Google Maps API, Figma, Firebase Authentication, Firebase Hosting, Firestore, Google Analytics, Trello, HTML, SCSS'
          },
          blurb: ''
        },
        {
          id: 'line_up',
          styles: {
            bg: '#000000',
            titleText: '#C3BBEA',
            descriptionText: '#eeeeee',
            otherText: '#eeeeee'
          },
          header: {
            project: '/line_up',
            title: 'LineUp',
            description: 'Turn your Twitter profile into a unique sharable business card.',
          },
          imgs: [
            { src: LINEUP_Home, alt: 'LineUp Home Page' },
            { src: LINEUP_Ad, alt: 'LineUp Ad' },
            { src: LINEUP_Search, alt: 'LineUp Search' },
            { src: LINEUP_AddToList, alt: 'LineUp Add To List' },
            { src: LINEUP_Icebreaker, alt: 'LineUp Icebreaker' },
            { src: LINEUP_Favorites, alt: 'LineUp Favorites' },
            { src: LINEUP_MentionsAndProfile, alt: 'LineUp Mentions And Profile' },
          ],
          metadata: {
            client: '',
            role: 'Creator, Product Design Lead & Developer',
            date: '2017',
            demo: 'https://double-are.github.io/lineup/#/',
            caseStudy: {
              title: 'The Magnificent Business Card',
              link: 'https://medium.com/@double_are/the-magnificent-business-cards-api-c7071f97f7cf'
            },
            tools: 'Flutter, Sketch, Twitter API, Google Analytics, QR, Geofire, Firebase, HTML, SCSS'
          },
          blurb: `The process of connecting with someone has turned into a simple trading of business cards, but business cards do not create relationships. 
            To differentiate ourselves from our competitors I focused on providing icebreakers, opportunities to learn more about our user's contacts, interests based on geolocation, and leveraging Meetup's API to find activites based on contacts interests.`
        },                                  
        {
          id: 'project_charter',
          styles: {
            bg: '#c1c4e3',
            titleText: '',
            descriptionText: '',
            otherText: ''
          },
          header: {
            project: '/project_charter',
            title: 'PROJECT: Charter',
            description: `Helping product owners clearly define requirements.`,
          },
          imgs: [
            { src: KillUX_Home, alt: 'KillUX Home Page' },
            { src: KillUX_TargetUser, alt: 'KillUX Target User' },
            { src: KillUX_Illustrations, alt: 'KillUX Illustrations' },
          ],
          metadata: {
            client: '',
            role: 'Creator, Product Design Lead, UX & UI Designer & Developer',
            date: '2021',
            demo: '',
            caseStudy: {},
            tools: 'VUE, Figma, Firebase Authentication, Firebase Hosting, Firestore, Google Analytics, HTML, SCSS'
          },
          blurb: `A system that helps product owners clearly define requirements for designers, scrum masters, stakeholders and engineers.`
        },
        {
          id: 'blocks',
          styles: {
            bg: '#BFBFBF',
            titleText: '',
            descriptionText: '',
            otherText: ''
          },
          header: {
            project: '',
            title: 'Blocks',
            description: 'Simple solution for adding personalization and recomendations to web applications in 7 minutes or less.',
          },
          imgs: [],
          metadata: {
            client: '',
            role: 'Creator & Product Design Lead',
            date: '2020',
            demo: '',
            caseStudy: {},
            tools: 'Gun.js - Web 3.0 Decentralized Graph Model Database'
          },
          blurb: `Customization is done by users. Personalization is done for users. 
          Unfortunately, personalization is typically demanding, complex, and costly. Developers control the content management system, but product managers, analysts, and designers use visitor data & analytics to derive insights and improve their services. 
          Even when PMs, analysts, and designers access this data, the suggested improvements aren't reflected in real-time.
          Applications often don't target messages to their unique audiences because their sites cater to the largest common denominator, and teams aren't measuring impact over time. 
          Blocks solve these problems by using a real-time, decentralized, offline-first, graph database engine.`
        },
        {
          id: 'discovery_one',
          styles: {
            bg: '',
            titleText: '',
            descriptionText: '',
            otherText: ''
          },
          header: {
            project: '',
            title: 'Discovery One',
            description: `An application that can identify an employee's location within a building and send relevant push notifications. Discovery One uses machine learning and the unique address of wifi routers.`,
          },
          imgs: [],
          metadata: {
            client: '',
            role: 'UI Designer',
            date: '2018',
            demo: '',
            tools: 'Flutter, Figma, node-wifi, random-forest-classifier'
          },
          blurb: ''
        },
      ]
    }
  }
}
</script>

<style lang="scss">
  @media (min-width: 1536px) {
    .container {
        max-width: 1280px;
    }
  }

  .router-link-exact-active {
    font-weight: 600;
    cursor: pointer; 
  }

  .nav {
    font-size: clamp(1rem, 0.7285rem + 1.2066vw, 1.5rem);
    line-height: clamp(1.5rem, 0.5769rem + 4.1026vw, 3.2rem);
    font-weight: 300;
  }
  .text-gradient {
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
    background-image: url('../assets/GlowText.jpeg');
  }
  .header-title {
    background-size: 100%;
    font-size: clamp(3rem, -1.0724rem + 18.0995vw, 10.5rem);
    line-height: clamp(2.7rem, -1.0724rem + 18.0995vw, 10.5rem);
    font-weight: 700;
  }
  .header-blurb {
    font-size: clamp(1rem, 0.1855rem + 3.6199vw, 2.5rem);
    line-height: clamp(1.5rem, 0.5769rem + 4.1026vw, 3.2rem);
    font-weight: 300;
  }
  .footer-container {
    background: #c1c4e3;

    .footer-title {
        font-size: clamp(1.9rem, 0.7054rem + 5.3092vw, 4.1rem);
        line-height: clamp(1.9rem, 0.8140rem + 4.8265vw, 3.9rem);

        .title {
          font-weight: 600;
        }

        .description {
          font-weight: 300;
        }
      }
    }
</style>
