import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from "firebase/app"
import "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyC1TGfCE2eUyQylyg79M2t56Bc736cvWUQ",
  authDomain: "double-are.firebaseapp.com",
  databaseURL: "https://double-are.firebaseio.com",
  projectId: "double-are",
  storageBucket: "double-are.appspot.com",
  messagingSenderId: "768886319903",
  appId: "1:768886319903:web:0b7ec37185a3a897331e2a",
  measurementId: "G-RQFF8B8VHB"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// alias
Vue.prototype.$analytics = firebase.analytics();

import './index.css'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
