<template>
  <div class="blurb text-base" :style="{ color: styles.otherText }">
    {{text}}
  </div>
</template>

<script>
export default {
  name: 'Blurb',
  props: {
    styles: Object,
    text: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .blurb {
    line-height: 25px;
    font-weight: 500;
  }
</style>
