<template>
  <div class="title-description mb-9">
    <div>{{project}}</div>
    <div class="title-description-content">
      <span class="title" :style="{ color: styles.titleText }">{{title}}</span> <div class="description" :style="{ color: styles.descriptionText }">{{description}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleDescription',
  props: {
    styles: Object,
    project: String,
    title: String,
    description: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .title-description {
    .title-description-content {
      font-size: clamp(1.9rem, 0.7054rem + 5.3092vw, 4.1rem);
      line-height: clamp(1.9rem, 0.8140rem + 4.8265vw, 3.9rem);

      .title {
        font-weight: 600;
      }

      .description {
        font-weight: 300;
      }
    }
  }
</style>
