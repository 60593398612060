
<template>
  <section class="project" :style="{ 'background': styles.bg }">
    <div class="container m-auto text-left px-5 py-24">
      <TitleDescription :styles="styles" :title="header.title" :description="header.description" :project="header.project" />
      <carousel :per-page="1" :navigationEnabled="true">
        <slide v-for="(i, index) in imgs" :key="index">
          <img class="w-full" :src="i.src" :alt="i.alt"/>
        </slide>
      </carousel>
      <div class="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 justify-between">
        <MetaData class="mr-8 mb-4" 
        :styles="styles" 
          :client="metadata.client" 
          :role="metadata.role" 
          :date="metadata.date" 
          :demo="metadata.demo"
          :caseStudy="metadata.caseStudy"
          :tools="metadata.tools" />
        <Blurb :styles="styles" :text="blurb" />     
      </div>
    </div>
  </section>
</template>

<script>
// import { Slider, SliderItem } from 'vue-easy-slider'
import { Carousel, Slide } from 'vue-carousel';
import TitleDescription from '@/components/TitleDescription.vue'
import MetaData from '@/components/MetaData.vue'
import Blurb from '@/components/Blurb.vue'
import CLOUDDETOUR_BuildingExperiment from '@/assets/cloud_detour/CLOUDDETOUR_BuildingExperiment.jpg'

export default {
  name: 'Project',
  components: {
    TitleDescription,
    MetaData,
    Blurb,
    // Slider, 
    // SliderItem
    Carousel,
    Slide
  },
  data() {
    return {
      CLOUDDETOUR_BuildingExperiment
    }
  },
  props: {
    id: String,
    styles: Object,
    header: Object,
    imgs: Array,
    metadata: Object,
    blurb: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .VueCarousel-dot-container {
    margin: 0 !important;

    * {
      margin-bottom: 20px !important;
    }
  }

  .VueCarousel-navigation-button {
    height: 100%;

    &:focus {
      outline: none;
    }
  }
  .VueCarousel-navigation-prev {
    left: 100px !important;
    width: 100px;
  }
   .VueCarousel-navigation-next {
    right: 100px !important;
    width: 100px;
  }
</style>
