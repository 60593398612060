<template>
  <div class="meta-data text-base" :style="{ 'color': styles.otherText }">
    <ul class="meta-content">
      <li><span :style="{ 'color': styles.titleText }">{{ client && `■ CLIENT: ` }}</span> {{ client }}</li>
      <li><span :style="{ 'color': styles.titleText }">{{ role && `■ ROLE: ` }}</span>{{ role }}</li>
      <li><span :style="{ 'color': styles.titleText }">{{ date && `■ DATE: ` }}</span>{{ date }}</li>
      <li>
        <span :style="{ 'color': styles.titleText }">{{ demo && `▸ DEMO: ` }}</span>
        <a class="underline" :href="demo">{{ demo }}</a>
      </li>
      <li>
        <span :style="{ 'color': styles.titleText }">{{ caseStudy.title && `▸ CASESTUDY: ` }}</span>
        <a v-if="caseStudy.title" :href="caseStudy.link" target="_blank" class="underline">{{ caseStudy.title }}</a>
      </li>
      <li><span :style="{ 'color': styles.titleText }">{{ tools && `▸ TOOLS: ` }}</span>{{ tools }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MetaData',
  props: {
    styles: Object,
    client: String,
    role: String,
    date: String,
    demo: String,
    caseStudy: String,
    tools: String
  }
}
</script>

<style scoped lang="scss">
  .meta-content {
    font-weight: 500;
  }
</style>
